import { useEffect, useState } from 'react';

function App() {

  const [todaysArr, setTodaysArr] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`https://todaysreservation-italy.vercel.app/todays`)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setTodaysArr(data)
        setLoading(false)
      })
  }, [])

  return (
    <>

      {
        loading && <h2 className='text-white text-center mt-52' >Loading...</h2>
      }

      {
        loading === false && <div className="App">
          <div className="w-full mt-14 md:mt-0  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className=" flex items-center justify-center mb-4 py-8">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white ">Today's Reservations</h5>
            </div>
            <div className="container mx-auto px-4 py-8">
              <h3 className='text-xl mb-5 font-bold leading-none text-gray-900 dark:text-white text-center '>Total : {todaysArr.length}</h3>
              {todaysArr.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {todaysArr.map((singcard) => (
                    <div key={singcard._id} className="bg-white p-4 rounded-lg shadow-md">
                      <p className="text-xl font-semibold">{singcard.name}</p>
                      <p className="text-gray-600">Persons: {singcard.person}</p>
                      <p className="text-gray-600">Date: {singcard.date}</p>
                      <p className="text-gray-600">Time: {singcard.slot}</p>
                      <p className="text-gray-600">Phone: {singcard.phone}</p>
                      <p className="text-gray-600">Email: {singcard.email}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-2xl text-blue-700 text-center">No reservation yet today</p>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default App;
